.form-container {
    margin: 100px auto;
    width: 1000px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
    height: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.close-btn {
    position: absolute;
    top: 2%;
    right: 3%;
    font-size: 1.5rem;
    z-index: 1;
    color: #fff;
    cursor: pointer;
}

.form-content-left {
    background: linear-gradient( 90deg, rgb(61, 131, 175) 0%, rgb(114, 174, 175) 100%);
    border-radius: 10px 0 0 10px;
    position: relative;
}

.form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-img-2 {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
}

.form-content-right {
    border-radius: 0 10px 10px 0;
    position: relative;
    background: linear-gradient(90deg, rgb(107, 107, 107) 0%, rgb(62, 61, 63) 100%);
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form h1 {
    font-size: 1rem;
    text-align: start;
    width: 80%;
    margin-bottom: 1rem;
    color: #fff;
}

.form-inputs {
    margin-bottom: 0.5rem;
    width: 80%;
}

.form-inputs p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
}

.form-input::placeholder {
    color: #595959;
    font-size: 12px;
}

.form-input-btn {
    width: 80%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background: linear-gradient( 90deg, rgb(61, 131, 175) 0%, rgb(114, 174, 175) 100%);
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
}

.form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient( 90deg, rgb(0, 99, 124) 0%, rgb(0, 96, 121) 100%);
    transition: all 0.4s ease-out;
}

.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
}

.form-input-login a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
}

@media screen and (max-width: 960px) {
    .form-container {
        width: 60%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .form-content-left {
        display: none;
    }
    .form {
        position: relative;
        margin-left: -10px;
    }
    .form h1 {
        font-size: 0.7rem;
    }
    .form-input p {
        font-size: 0.5rem;
    }
    .form-content-right {
        border-radius: 10px;
        height: 100%;
    }
    .form-input::placeholder {
        font-size: 10px;
    }
    .form-label {
        font-size: 0.65rem;
    }
}
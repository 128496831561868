.services-background{
    width: 100vw;
    height: 100vh;
    bottom: 0px;
}

.background {
    object-fit: cover;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index:-1;
}

.services {
    font-size: 100px;
    font-weight:100;
    color:black;
}

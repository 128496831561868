.sign-up-background {
    width: 100vw;
    height: 100vh;
    bottom: 0px;
}

.background {
    object-fit: cover;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
}
.background {
    position: fixed;
}


.account-content-container {
    position: relative;
    display: flexbox;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65vw;
    height: 35vh;
    background-color: black;
    background-image: linear-gradient(135deg, #356d77 0%, #135161 70%);
    border-radius: 20px;
    margin: 150px auto;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.6), 0 7px 20px 0 rgba(0, 0, 0, 0.4);
}

hr {
    margin: auto;
    width: 45vw;
    border: 2px solid rgb(0, 168, 168);
    border-radius: 5px;
}

.account-content-container h1 {
    color: white;
    font-size: 3rem;
    font-weight: lighter;
    margin: 10px;
    padding-top: 10px;
    text-align: center;
}

.account-content-container ul {
    position: absolute;
}

.account-content-container li {
    padding-left: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
    list-style-type:none;
    margin: 30px;
}

@media screen and (max-width:960px) {
    .account-content-container h1 {
        font-size: 1.5rem;
    }

    hr {
        width: 60vw;
        border: 1px solid rgb(0, 168, 168);
    }

    .account-content-container {
        height: 45vh;
        width: 90vw;
    }
}

.navbar {
  background: linear-gradient(90deg, rgb(0, 84, 105) 0%, rgb(0, 84, 105) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-money-check-alt {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.dropdown {
  width: 10%;
}


.dropdown-toggle {
  margin: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  color: #fff;
  padding: 5px 5px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
  font-size: 15px;
}

#chevron-down {
  margin: 3px;
  padding: 5px;
}

.dropdown-toggle:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.dropdown-menu {
  background:#fff;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.dropdown-list-item {
  list-style: none;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  color: #242424;
  transition: all 0.3s ease-out;
  font-size: 13px;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.dropdown-item:hover {
  background-color:rgb(0, 84, 105);
  color: #fff;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(17, 127, 155);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .dropdown {
    display: block;
    margin: 2rem auto;
  }

}


@media  screen and (max-height: 500px) {
  .nav-menu {
    overflow:scroll;
    bottom: 20px;
    scroll-margin-bottom: 20px;
  }

  .nav-links {
    padding: 1rem;
    height: 1rem;
  }

  .nav-links-mobile {
    padding: 10px 16px;
    font-size: 1rem;
    margin: 0rem 2rem 0 4rem;
  }

  .dropdown {
    margin: 0 18rem 7rem 18rem;
  }
  .dropdown-toggle {
    margin: -1rem 1rem;
  }

}